import $ from 'jquery'

(function ($) {
    /*-------------------
    Accordion START
    ---------------------*/
    /* für ganz große Inhalte wird nach .5s die maxheight entfernt. */
    $('.accordion > ul > li').each(function (index, element) {
        let content = $(this).find('> div');
        content.css('height', 'auto');
        tmpHeight = content.height();
        content.css('height', tmpHeight);
        // content.css('height',0);
        content.parent().addClass('transition');

    });

    $('.accordion > ul > li > label').on('click', function (event) {
        //event.preventDefault();
        var target = $(this).prev();

        if ($(this).parent().hasClass('active')) {
            $(this).parent().removeClass('active');
            target.prop('checked', false);
            //console.log(target);
        } else {
            target.prop('checked', true);
            //console.log('else');
            target.parents('ul').siblings('> li.active').removeClass('active');
            window.setTimeout(function () {
                //console.log(target.parent());
                target.parent().addClass('active');
            }, 1500);
//console.log($(this).parents('.accordion'));
            if ($(this).parents('.accordion').hasClass('accordion-scroll-1')) {
                let pos = target.offset().top - $('body > header').height();
                $('html, body').animate({
                    scrollTop: pos
                }, 800);
            }
        }

    });

    /*--- Accordion END */



    $('.group-accordion .toggle label').on( 'click', function( jQEvent ) {
        var target = $(this).parent().prev();
        if(target.prop('checked')){
            jQEvent.preventDefault();
            target.prop('checked', false);
        }
        //console.log(target.prop('checked'));
    });

})($);
