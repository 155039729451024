/*! simpleScroller v3.3.2 | (c) Alexander Klenner */
/* scroller für Bühne etc. */


(function ($) {
    /**
     * @param {{
     * dur: number,
     * auto: boolean,
     * pause: number,
     * counter: boolean,
     * numbers: boolean,
     * numbers_divisor: string,
     * skip: number,
     * dir: string,
     * align: string,
     * onswipe: string,
     * hover: boolean
     * }} params
     */
    $.fn.simpleScrollerSet = function (params) {
        return this.each(function () {
            var $scroller = $(this);

            $scroller.data('dur', params.dur || 600);
            if ($scroller.is('.vertical')) {
                $scroller.data('dir', params.dir || 'vertical');
            } else {
                $scroller.data('dir', params.dir || 'horizontal');
            }
            $scroller.data('align', params.align || 'center');
            $scroller.data('auto', params.auto || false);
            $scroller.data('hover', params.hover || false);
            $scroller.data('pause', params.pause || 4000);
            $scroller.data('skip', params.skip || 0);
            $scroller.data('onswipe', params.onswipe || 'animate');
            $scroller.data('numbers', params.numbers || false);
            $scroller.data('numbers_divisor', params.numbers_divisor || ' von ');
        });
    };

    /**
     * @param {{
     * dur: number,
     * auto: boolean,
     * pause: number,
     * counter: boolean,
     * numbers: boolean,
     * numbers_divisor: string,
     * skip: number,
     * dir: string,
     * align: string,
     * onswipe: string,
     * hover: boolean
     * }} params
     */
    $.fn.simpleScroller = function (params) {
        return this.each(function () {
                /* params = Array(
                  dur: 1000, // Animationszeit in ms
                  auto: true|false, // Autoanimation an/aus
                  pause: 5000, //Pause zwischen Autoanimation in ms
                  counter: true|false, // Zähler (Punkte) an/aus
                  numbers: true|false, // Zähler (X von Y) an/aus
                  numbers_divisor: 'von', // Trenner des Zählers (X *von* Y)
                  skip: 0, // Anzahl von zu überspringenden Seiten bei next/prev
                  dir: 'horizontal'|'vertical', // Richtung. Default: 'vertical'
                  align: 'left'|'center'|'right' // Position aktives Element. Default: 'center'
                  onswipe: 'animate'|'skip' Animation beim Swipen ein/ausschalten
                );
                */
                var $scroller = $(this),
                    isSlider = true;

                $scroller.simpleScrollerSet(params);

                $scroller.data('page_visible', $scroller.children("li:first-child"));
                $scroller.data('page_first', $scroller.children("li:first-child"));
                $scroller.data('page_last', $scroller.children("li:last-child"));
                $scroller.data('is_hover', false);

                $scroller.addClass("js").parent().addClass("js");
                $scroller.append($scroller.children("li")); // Leerzeichen zwischen li-Tags entfernen.

                if ($scroller.data('dir') === 'horizontal') {
                    $scroller.removeClass('vertical');

                    if (($scroller.innerWidth() < $scroller.data('page_last').width() * $scroller.children("li").length) || (($scroller.data('page_last').offset().left - $scroller.offset().left) > ($scroller.innerWidth() - $scroller.data('page_last').width()))) {

                        let $controls = $('<div class="scroller_controls"></div>'),
                            $left = $('<button class="scroller_left" title="Nach links" aria-hidden="true" tabindex="-1"></button>'),
                            $right = $('<button class="scroller_right" title="Nach rechts" aria-hidden="true" tabindex="-1"></button>'),
                            $indicator = $('<div class="scroller_indicator"></div>'),
                            $numbers = $('<div class="scroller_numbers"></div>'),
                            $counter = $('<div class="scroller_counter"></div>');

                        $scroller.after($controls);
                        $controls.append($left);
                        $controls.append($right);


                        if (typeof (params) === "object" && params.numbers) {
                            $indicator.append($numbers);
                            $controls.append($indicator);
                        }

                        if (typeof (params) === "object" && params.counter) {
                            $indicator.append($counter);
                            $controls.append($indicator);
                        }

                        $scroller.children("li").each(function (i) {
                            var $page = $(this),
                                $toggle = $('<a href="#' + (i + 1) + '" title="Seite ' + (i + 1) + '" aria-hidden="true" tabindex="-1"><strong>' + (i + 1) + '</strong></a>'),
                                $clone = $page.clone(true);

                            $counter.append($toggle);
                            $page.data('counter', $toggle);

                            // Klone zum scrollen über start/ende anlegen.
                            // Klone sollen nicht mit der Tastatur erreichbar sein, dafür reichen die Originale.
                            $clone.addClass('clone').attr({
                                'aria-hidden': 'true',
                                'tabindex': '-1'
                            }).find('a,button,:input').attr('tabindex', '-1');

                            // Seiten ohne Links bekommen einen Tabindex, um mit der Tastatur anspringbar zu sein.
                            if ($page.find('a,button,:input').length < 1) {
                                $page.attr('tabindex', 0);
                            } else {
                                $page.removeAttr('tabindex');
                            }

                            // Original merken
                            $clone.data('original', $page);
                            $scroller.data('page_first').before($clone);
                            $scroller.append($clone.clone(true));

                            $toggle.on('click', function () {
                                $scroller.simpleScrollerTo($page, 'counter');
                                return false;
                            });

                            $page.add($page.find('a,button,:input')).on('focus', function () {
                                $scroller.simpleScrollerTo($page, 'counter', 0);
                            });

                        });

                        $scroller.data('page_visible').data('counter').addClass("on");

                        $left.on('click', function () {
                            $scroller.simpleScrollerTo($scroller.data('page_visible').prevAll("li").eq($scroller.data('skip')), 'left');
                            return false;
                        });

                        $right.on('click', function () {
                            $scroller.simpleScrollerTo($scroller.data('page_visible').nextAll("li").eq($scroller.data('skip')), 'right');
                            return false;
                        });

                        if (typeof (params) === "object" && params.hover === true) {
                            $left.hover(
                                function () {
                                    $scroller.data('is_hover', 'left');
                                    $left.click();
                                },
                                function () {
                                    $scroller.data('is_hover', false);
                                }
                            );
                            $right.hover(
                                function () {
                                    $scroller.data('is_hover', 'right');
                                    $right.click();
                                },
                                function () {
                                    $scroller.data('is_hover', false);
                                }
                            );
                        }


                        // swiping mit animation
                        if ($scroller.data('onswipe') === 'animate') {
                            var scrollStart = 0;
                            $scroller.on('movestart', function (e) {
                                if ($scroller.data('timer')) // reset autoanim
                                {
                                    window.clearTimeout($scroller.data('timer'));
                                    $scroller.data('timer', false);
                                }
                                if (Math.abs(e.distX) < Math.abs(e.distY)) // reaktiviere hoch/runter scrollen der gesamten Seite
                                {
                                    e.preventDefault();
                                }

                                scrollStart = $scroller.scrollLeft();
                            });

                            $scroller.on('move', function (e) {
                                if (Math.abs(e.distX) > Math.abs(e.distY)) // seitlich scrollen der Bühne umsetzen
                                {
                                    $scroller.scrollTo({top: 0, left: "-=" + (e.deltaX / 1) + "px"}, 0);
                                }
                            });

                            $scroller.on('moveend', function (e) {

                                $scroller.scrollTo({
                                        top: 0,
                                        left: "-=" + (e.distX - (scrollStart - $scroller.scrollLeft())) + "px"
                                    }, 0, // Abgleichen von angeforderter und gescrollter Distanz.
                                    {
                                        onAfter: function () {
                                            var newPage = false,
                                                breakpoint;

                                            $scroller.find("li").each(function () {
                                                var thisPage = $(this);
                                                switch ($scroller.data('align')) {
                                                    case 'center':
                                                        breakpoint = $scroller.innerWidth() / 2;
                                                        break
                                                    case 'left':
                                                        breakpoint = thisPage.outerWidth() / 2;
                                                        break
                                                    case 'right':
                                                        breakpoint = -($scroller.innerWidth() - thisPage.outerWidth() / 2);
                                                        break
                                                }
                                                if (thisPage.position().left < breakpoint &&
                                                    (thisPage.position().left + thisPage.outerWidth()) > breakpoint) // Finde angescrolltes Element
                                                {
                                                    newPage = thisPage;
                                                    return false;
                                                }
                                            });
                                            $scroller.simpleScrollerTo(newPage, 'auto', 200);
                                        }
                                    });
                            });
                        }
                        // swiping ohne animation.
                        else if ($scroller.data('onswipe') === 'skip') {
                            var lastSwitch = 0;
                            $scroller.on('movestart', function (e) {
                                lastSwitch = 0;
                                if ($scroller.data('timer')) // reset autoanim
                                {
                                    window.clearTimeout($scroller.data('timer'));
                                    $scroller.data('timer', false);
                                }
                                if (Math.abs(e.distX) < Math.abs(e.distY)) // reaktiviere hoch/runter scrollen der gesamten Seite
                                {
                                    e.preventDefault();
                                }
                            });

                            $scroller.on('move', function (e) {
                                if ($scroller.data('timer')) // reset autoanim
                                {
                                    window.clearTimeout($scroller.data('timer'));
                                    $scroller.data('timer', false);
                                }
                                if (Math.abs(e.distX) > Math.abs(e.distY)
                                    && Math.abs(e.distX - lastSwitch) > ($scroller.width() / 4)
                                ) {
                                    var $page = $scroller.data('page_visible');
                                    if (e.distX - lastSwitch < 0) {
                                        $page = $page.next();
                                    } else {
                                        $page = $page.prev();
                                    }
                                    lastSwitch = e.distX;
                                    $scroller.simpleScrollerTo($page, 'counter', 0);
                                }
                            });
                            $scroller.on('moveend', function (e) {
                                lastSwitch = 0;
                                if ($scroller.data('auto')) //autoanim
                                {
                                    $scroller.data('timer', window.setTimeout(function () {
                                        $right.click();
                                    }, $scroller.data('pause')));
                                }
                            });
                        }

                    } else {
                        isSlider = false;
                    }
                } else { // vertikales scrollen?
                    $scroller.addClass('vertical');

                    var thisUp = $('<button class="scroller_up" title="Nach oben"></button>');
                    $scroller.after(thisUp);
                    var thisDown = $('<button class="scroller_down" title="Nach unten"></button>');
                    $scroller.after(thisDown);

                    thisDown.on('click', function () {
                        $scroller.animate({
                            scrollTop: '+=' + ($scroller.innerHeight())
                        }, $scroller.data('dur'));
                        return false;
                    });

                    thisUp.on('click', function () {
                        $scroller.animate({
                            scrollTop: '-=' + ($scroller.innerHeight())
                        }, $scroller.data('dur'));
                        return false;
                    });

                    $(window).on('load resize', function () {
                        if ($scroller.get(0).scrollHeight <= Math.round($scroller.outerHeight())) {
                            thisDown.hide();
                            thisUp.hide();
                        } else {
                            thisDown.show();
                            thisUp.show();
                        }
                    });

                }

                if ($scroller.data('auto') && isSlider) {
                    $scroller.data('timer', window.setTimeout(function () {
                        $right.click();
                    }, $scroller.data('pause')));
                }

                var scaleTimer = false;
                $(window).resize(function () {
                    window.clearTimeout(scaleTimer);
                    scaleTimer = window.setTimeout(function () { // Ansicht zentrieren mit minimaler Verzögerung für Rendern.
                        $scroller.simpleScrollerTo($scroller.data('page_visible'), 'auto', 0);
                    }, 100);
                });

                window.setTimeout(function () { // Ansicht zentrieren mit minimaler Verzögerung für Rendern.
                    $scroller.simpleScrollerTo($scroller.data('page_visible'), 'auto', 0);
                }, 10);
            }
        );
    };

    $.fn.simpleScrollerTo = function ($page, direction, duration) {
        return this.each(function () {
            let $scroller = $(this),
                $controls = $scroller.nextAll('.scroller_controls'),
                $right = $controls.find('button.scroller_right'),
                $left = $controls.find('button.scroller_left'),
                $counter = $controls.find('.scroller_counter'),
                $numbers = $controls.find('.scroller_numbers');


            if ($page === 0) {
                $page = $scroller.data('page_visible');
            } else if (typeof ($page) === 'number') {
                index = $page + $scroller.find('li').index($scroller.data('page_visible'));
                $page = $scroller.find('li:eq(' + index + ')');
            }

            if (typeof (duration) === 'undefined') {
                duration = $scroller.data('dur');
            }

            if ($scroller.data('timer')) // reset autoanim
            {
                window.clearTimeout($scroller.data('timer'));
                $scroller.data('timer', false)
            }

            if (!$scroller.is(':animated') || $scroller.data('is_hover') === direction) {
                $scroller.data('page_visible', $page);
                var thisEasing = 'swing',
                    thisOffset;
                if ($scroller.data('is_hover') === direction) {
                    thisEasing = 'linear';
                }

                switch ($scroller.data('align')) {
                    case 'center':
                        thisOffset = -($scroller.innerWidth() - $scroller.data('page_visible').outerWidth()) / 2;
                        break
                    case 'left':
                        thisOffset = 0;
                        break
                    case 'right':
                        thisOffset = -($scroller.innerWidth() - $scroller.data('page_visible').outerWidth());
                        break
                }

                $scroller.scrollTo(
                    $scroller.data('page_visible'),
                    duration,
                    {
                        easing: thisEasing,
                        offset: thisOffset,
                        onAfter: function () {
                            // Seite ist ein Klon? Original anspringen.
                            if ($scroller.data('page_visible').data('original')) {
                                $scroller.simpleScrollerTo($scroller.data('page_visible').data('original'), 'auto', 0);
                            } else {
                                $scroller.data('page_visible').addClass('active').siblings('li').removeClass('active');
                            }
                            if ($scroller.data('is_hover') === direction) {
                                if (direction === 'left') {
                                    $left.click();
                                } else {
                                    $right.click();
                                }
                            }

                            if ($numbers) {
                                let $pages = $scroller.find('li:not(.clone)'),
                                    max = $pages.length,
                                    curr = $pages.index($scroller.data('page_visible')) + 1;

                                $numbers.text(curr + $scroller.data('numbers_divisor') + max);
                            }
                            if ($counter) {
                                $counter.children("a").removeClass("on");
                                if ($scroller.data('page_visible').data('counter')) {
                                    $scroller.data('page_visible').data('counter').addClass("on");
                                }
                            }
                        }
                    }
                );
            }
            if ($scroller.data('auto')) //autoanim
            {
                $scroller.data('timer', window.setTimeout(function () {
                    $right.click();
                }, $scroller.data('pause')));
            }
        });
    };
})(jQuery);
