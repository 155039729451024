import $ from 'jquery'

$(document).ready(function () {
    // Dynamische checks und Validierung aus dem FormGen.
    $('form.sfg_form').each(function () {
        let $form = $(this);
        var sfgElements = [];

        let myCheck = function () {
            $form.find(':input[name^="form\["]').each(function () {
                let $field = $(this),
                    names = $field.attr('name').match(/^form\[([^\]]+)\]/),
                    name = (names) ? names[1] : false,
                    $siblings = $form.find('input[name="' + $.escapeSelector($field.attr('name')) + '"]');

                if (name) {
                    // Checks sehen z.B. so aus: "%personen% >= 2"
                    // Daher muß eine Variable "personen" angelegt werden, die den Feldwert für spätere Prüfung enthält.
                    // Der Name ist Teil der ID.
                    if ($field.is('input[type="checkbox"]') && $siblings.length > 1) {
                        sfgElements[name] = [];
                        $siblings.filter(':checked').each(function (i) {
                            sfgElements[name][i] = $(this).val();
                        });
                    } else if (
                        $field.is('input[type="checkbox"]') && $siblings.length === 1
                        || $field.is('input[type="radio"]')
                    ) {
                        sfgElements[name] = null;
                        $siblings.filter(':checked').each(function (i) {
                            sfgElements[name] = $(this).val();
                        });
                    } else {
                        sfgElements[name] = $field.val();
                    }
                }
            });

            $form.find('input,textarea,select,fieldset,.fieldType_textblock > div').each(function () {
                let $field = $(this),
                    $label = $('label[for="' + $field.attr('id') + '"]'),
                    $parent = $field.parents('.gridCol:first'),
                    con;

                // wir prüfen mit eval, ob die eingegebene Plausis zutreffen, und handeln entsprechend.
                try {
                    // Required setzen/löschen
                    if ($field.data('requiredif')) {
                        // con enthält einen redaktionell eingegeben JS Befehl, z.B. "%feldname% > 1"
                        con = $field.data('requiredif').replace(/%([a-z0-9_]+)%/g, "sfgElements['$1']");
                        if (eval('(' + con + ')')) {
                            $field.attr('required', 'required');
                            $label.children('span.required_field').remove();
                            $label.append('<span class="required_field">*</span>')
                        } else {
                            $field.removeAttr('required');
                            $label.children('span.required_field').remove();
                        }
                    }

                    // Disabled setzen/löschen
                    if ($field.data('disabledif') && !$form.is('form[disabled]')) {
                        con = $field.data('disabledif').replace(/%([a-z0-9_]+)%/g, "sfgElements['$1']");
                        if (eval('(' + con + ')')) {
                            $field.attr('disabled', 'disabled');
                        } else {
                            $field.removeAttr('disabled');
                        }
                    }

                    // Feld ausblenden/anzeigen
                    if ($field.data('hiddenif')) {
                        con = $field.data('hiddenif').replace(/%([a-z0-9_]+)%/g, "sfgElements['$1']");
                        if ($parent.siblings().length === 0) {
                            $parent = $parent.parent('.gridRow');
                        }
                        if (eval('(' + con + ')')) {
                            $parent.hide();
                        } else {
                            $parent.show();
                        }
                    }

                    // Felder prüfen, die identisch sein sollen.
                    if ($field.data('equalsto')) {
                        var name = $field.data('equalsto').replace(/%([a-z0-9_]+)%/g, "$1"),
                            $other = $form.find('input[name="' + $.escapeSelector('form[' + name + ']'));

                        $other.on('change', function () {
                            if ($field.val().length > 0) {
                                $field.trigger('change');
                            }
                        });

                        $field.on('change', function () {
                            if ($field.val() !== $other.val()) {
                                $field.get(0).setCustomValidity("Die Werte müssen identisch sein.");
                                $field.trigger('invalid');
                                $other.get(0).setCustomValidity("Die Werte müssen identisch sein.");
                                $other.trigger('invalid');
                            } else {
                                $field.get(0).setCustomValidity('');
                                $field.trigger('propertychange');
                                $other.get(0).setCustomValidity('');
                                $other.trigger('propertychange');
                            }
                        });
                    }

                } catch (e) {
                    // hier nix.
                }

            });
        };

        $form.find('input,textarea,select').on('change', function () {
            myCheck();
        });
        myCheck();


        // JS Check für HTML5-Validierung
        // HTML-Klon der Fehlermeldung aus dem PHP Check, scrollen zum ersten Fehler.
        $form.find(':input').each(function () {
            var $input = $(this),
                input = $(this).get()[0],
                $parent = $input.parents('p:first'),
                $labelText = $parent.children('label').children('span:first');

            $input.on('invalid', function (e) {
                e.preventDefault();
                if (!e.target.validity.valid) {
                    // e.target.setCustomValidity($input.data('message'));

                    var message = $input.data('message');
                    if (!message) {
                        message = input.validationMessage;
                    }

                    $parent.addClass('form_error');
                    $parent.find('.form_error_text').remove();
                    $labelText.append('<strong class="form_error_text">' + message + '</strong>');
                    $form.find('input:invalid,textarea:invalid,select:invalid').filter(':first').focus();

                } else {
                    $parent.removeClass('form_error');
                    $parent.find('.form_error_text').remove();
                }
            });

            $input.on('input propertychange', function (e) {
                $parent.removeClass('form_error');
                $parent.find('.form_error_text').remove();
                input.setCustomValidity("");
            });

            $input.on('change', function (e) {
                $parent.removeClass('form_error');
                $parent.find('.form_error_text').remove();
                input.setCustomValidity("");
                window.setTimeout(function () {
                    input.checkValidity();
                }, 300);
            });
        });
    });
});
