import $ from 'jquery'

(function ($) {

    $('html').addClass('js');
    var isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));


    var scrollDirection = 0;
    var headerHeight = $('header').height();
    $(window).scroll(function (event) {
        var st = $(this).scrollTop();
        if (st <= headerHeight) {
            $('html').removeClass('fixed');
        } else {
            $('html').addClass('fixed');
        }
        scrollDirection = st;
    });

    /*-------------------
    Small Screen Navi Solution START
    ---------------------*/
    $( ".nav__main > ul > li.has-children" ).hover(
        function() {
            var childUL = $( this ).find('> ul');
            var dropdownHeight = childUL.height();
            /* Set Child UL Height according to visible Space */
            var dropdownHeightPosition = childUL.offset().top - $(window).scrollTop();
            var windowHeight = $(window).height();
            var headerHeight = $('body > header').height();
            if(windowHeight < (dropdownHeight + headerHeight)){
                childUL.height(windowHeight - dropdownHeightPosition);
                $( this ).addClass('navi--overflow');
            }
        }, function() {
           $( this ).find('> ul').removeAttr('style');
           $( this ).removeClass('navi--overflow');
        }
    );
    /*--- Small Screen Navi Solution END */

    /*-------------------
    search START
    ---------------------*/
    $("header .search").on('click', '.search__open', function (event) {
        if (!$('html').hasClass('search__is_open')) {
            event.preventDefault();
            $('html').addClass('search__is_open');
        }
        //$('html').addClass('search__is_open');
    });
    $("header .search").on('click', '.search__close', function (event) {
        event.preventDefault();
        $('html').removeClass('search__is_open');
    });
    //$('footer ul').append('<li><a class="cookielink" href="#">Cookies</a></li>');

    /*--- search END */


    /*-------------------
    Scrollto START
    ---------------------*/
    $("a.scrollTo").on('click', function (event) {
        event.preventDefault();
        let offset = $('#page-wrapper > header').height();
        //let offset = 0;
        var url = $(this).attr('href');
        var hash = url.substring(url.indexOf("#") + 1);

        let pos;
        if (hash == 'top') {
            pos = 0;
        } else if (hash == 'next') {
            //$(this).parent
            pos = $(this).parents('.intro').next().offset().top - offset;
        } else {
            pos = $('#' + hash).offset().top - offset;
        }

        //console.log(pos);
        $('html, body').animate({
            scrollTop: pos
        }, 800, 'easeOutQuad');
        return false;
    });
    /*--- Scrollto END */


    /*-------------------
    navigation START
    ---------------------*/
    function setMobileNav() {
        $(".show-children").on('click', function (event) {
            var parent = $(this).parent();
            if (parent.hasClass('open')) {
                parent.removeClass('open');
            } else {
                parent.siblings().removeClass('open');
                parent.addClass('open');
            }
        });

        //copy Meta Links
        var nav__sub = $('<div class="nav__sub"></div>');
        nav__sub.append($('.nav__link').clone());
        nav__sub.append($('.nav__meta').clone());
        nav__sub.append($('.nav__lang').clone());
        $(".nav__main").append(nav__sub);
    }

    setMobileNav();

    /*--- navigation END */

    /*-------------------
    SimpleScroller START
    ---------------------*/
    // Bühne ohne X von Y
    $('.scroller.stage ul').simpleScroller({
        counter: true, // Zähler Punkte
        numbers: true, // Zähler X von Y
        dur: 1,
        auto: true, // Automatisches Blättern
        pause: 10000, // Pause bei automatischem Blättern
        onswipe: 'skip'
    });
    // Optimierung Ladezeit für Bühnenseiten:
    // all außer der erstren hartcodiert mit style="display: none" versteckt, mit Versatz nachladen.
    window.setTimeout(function (){
        $('.scroller.stage ul li').removeAttr('style');
    }, 5000);

    // Galerie mit X von Y
    $('.content-container .scroller ul').simpleScroller({
        counter: true, // Zähler Punkte
        numbers: true, // Zähler X von Y
        dur: 1,
        auto: true, // Automatisches Blättern
        pause: 10000, // Pause bei automatischem Blättern
        onswipe: 'skip'
    });

    // // Slider Controls in DIV packen
    // $('.scroller ul').each(function (index, element) {
    //     var sliderWrapper = $(this).parent();
    //     var controls = $('<div class="scroller__controls"></div>');
    //     controls.append(sliderWrapper.find('.scroller_left'));
    //     controls.append(sliderWrapper.find('.scroller_right'));
    //     controls.append(sliderWrapper.find('.scroller_counter'));
    //     $(this).after(controls);
    // });
    // /*--- SimpleScroller END */

    /*-------------------
    Datepicker START
    ---------------------*/
    if (!isTouch) {
        $('.sfg_form input[type="date"]').datepicker({
            format: 'dd.mm.yyyy',
            language: 'de-DE',
            weekStart: 1,
            //startView: 2,
            //yearFirst: true,
            autoHide: true,
            template: '<div class="datepicker-container">\n' +
                '  <div class="datepicker-panel" data-view="years picker">\n' +
                '    <ul>\n' +
                '      <li class="current" data-view="years current"></li>\n' +
                '      <li class="prev" data-view="years prev"></li>\n' +
                '      <li class="next" data-view="years next"></li>\n' +
                '    </ul>\n' +
                '    <ul data-view="years"></ul>\n' +
                '  </div>\n' +
                '  <div class="datepicker-panel" data-view="months picker">\n' +
                '    <ul>\n' +
                '      <li class="current" data-view="year current"></li>\n' +
                '      <li class="prev" data-view="year prev"></li>\n' +
                '      <li data-view="year next"></li>\n' +
                '    </ul>\n' +
                '    <ul data-view="months"></ul>\n' +
                '  </div>\n' +
                '  <div class="datepicker-panel" data-view="days picker">\n' +
                '    <ul>\n' +
                '      <li class="current" data-view="month current"></li>\n' +
                '      <li class="prev" data-view="month prev"></li>\n' +
                '      <li class="next" data-view="month next"></li>\n' +
                '    </ul>\n' +
                '    <ul class="week" data-view="week"></ul>\n' +
                '    <ul data-view="days"></ul>\n' +
                '  </div>\n' +
                '</div>'
        })
        .attr({
            'type': 'text'
            //'placeholder': $(this).attr
        })
        .addClass('datepicker');
    }
    /*--- Datepicker END */

    /*-------------------
    Video START
    ---------------------*/
    $('.media-container.video video').each(function (index, element) {
        $(this).removeAttr('controls');

        $(this).on("click", function (e) {
            e.preventDefault();
            $(this).parent().addClass('played');
            $(this).attr('controls', 'controls');

            video = this;

            if (video.paused == true) {
                // Play the video
                video.play();
            } else {
                // Pause the video
                video.pause();
            }
        });
    });
    /*---  END */

    /*-------------------
    Scroller START
    ---------------------*/

    $('.teaser-scroller, .scrollbar-inner, .table-responsive').each(function (index, element) {
        $(this).addClass('scrollbar-inner');
        $(this).scrollbar({
            "scrollx": "advanced",
            "scrolly": "false"
        });
    });
    /*--- Scroller END */


    /*-------------------
    formGenJump START
    ---------------------*/
    if ($('.sfg_form').length > 0) {
        var isFormsubmit = false;
        var url = window.location.href;
        var form = $('.sfg_box');
        if ($('.form_error_text').length > 0 || url.indexOf('formpage=2') > -1) {
            isFormsubmit = true;
        }

        if (isFormsubmit) {
            $('body, html').scrollTop(form.offset().top - 200);
        }
    }
    /*---  END */

    $('.sfg_form input[title][title!=""]:not(.form_error *)').each(function () {
        $(this).after('<div class="sfg_hint"><span>' + $(this).attr('title') + '</span></div>'
        ).removeAttr('title');
    });
    //open cookie settings
    $("a.cookielink").on('click', function (event) {
        event.preventDefault();
        $('.cookie').show();
        $('.ch-cookie-consent').show();
    });
    //search - submit on checkbox change
    $("[id^=form_filterContentType]").change(function (){
        $("#extended_search_from").submit();
    })

})($);
if (typeof ym !== 'undefined') {
    ym.ready(function (modules) {

        $('.interactivemap').each(function () {
            let $map = $(this),
                mapDiv = $map.get()[0],
                $wrapper = $map.parent('.interactivemap_wrapper'),
                route_goal = $map.data('route_goal'),
                route_goal_label = $map.data('route_goal_label'),
                lat,
                lng,
                map,
                iconMarkerLatLng,
                marker,
                icon = new modules.provider.Icon({
                    iconUrl: 'http://cdn.yellowmap.de/yellowmaps/img/marker.svg',
                    iconRetinaUrl: 'http://cdn.yellowmap.de/yellowmaps/img/marker.svg',
                    iconSize: [30, 49],
                    iconAnchor: [15, 49],
                    popupAnchor: [0, -48]
                }),
                geoCoder = new ym.services.GeoCoder();

            geoCoder.geocode(route_goal);
            geoCoder.on('success', function (req, res) {
                console.log($map === $map, route_goal, req, res.body.bbox);
                lat = res.body.bbox[1];
                lng = res.body.bbox[0];
                map = ym.map(mapDiv, {
                    // Geographische Länge und Breite definieren.
                    center: ym.latLng(lat, lng),
                    // Zoomstufe festlegen.
                    zoom: 12
                });

                iconMarkerLatLng = ym.latLng(lat, lng);
                marker = new modules.provider.Marker(iconMarkerLatLng);

                marker.setIcon(icon);
                // Marker einzeichnen
                marker.addTo(map);
            });

        });
    });
}
