/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
//


import './css/theme.scss';
import './img/apple-touch-icon.png';
import './img/favicon-194x194.png';
import './img/favicon.ico';

// plugins
import $ from 'jquery'

window.$ = window.jQuery = $;

import '@chenfengyuan/datepicker'
import 'jquery.event.move'
import 'jquery.easing'
//import 'jquery.scrollto'
import './js/jquery.scrollTo.min'
//import 'jquery-inview';
import './js/frontend'
import './js/scrollbar/jquery.scrollbar.min'
import './js/simpleScroller'
import 'video.js'
// cookie extension
///import './../../vendor/connectholland/cookie-consent-bundle/Resources/public/js/cookie_consent'


// custom code
//import './js/highcharts';
import './js/accordion'
import './js/ext'
import './js/scripts/obfcm'

// start the Stimulus application
//import './bootstrap';
